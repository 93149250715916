import React from "react"
import Layout from "../components/layout"

import {GatsbySeo} from "gatsby-plugin-next-seo/src/meta/gatsby-seo";
import HeaderH2 from "../components/headerH2";
import Dashboard1 from "../assets/dashboard/total_active_subscriber.png";
import Dashboard2 from "../assets/dashboard/monthly-recurring-revenue.png";
import Dashboard3 from "../assets/dashboard/monthly-tracked-revenue.png";

export default function LinkfiveDashboard() {
  return (
    <>
      <GatsbySeo title="LinkFive Dashboard"
                 description={`Our customers are already using LinkFive as their subscription management system in their mobile apps. We have collected a few examples of how LinkFive is used in their production applications.`}
      />
      <Layout fullWave="false" bottomWave="false" callToAction="true" utmSource="showcase">
        <div className="pt-24 pb-12 bg-l6-background">
          <div
            className="container md:max-w-6xl px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center lg:mb-12 lg:mt-12">
            <div
              className="md:ml-14 lg:mb-10 mb-0 md:pt-16 md:max-w-4xl md:w-full flex flex-col items-start">
              <h1 className="font-extrabold leading-10 tracking-tight sm:leading-none md:text-6xl sm:text-4xl text-2xl">
                LinkFive Web Dashboard
              </h1>
              <p className="my-5 text-lg text-gray-600 leading-relaxed md:w-4/5 md:text-xl md:mx-0 ">
                The LinkFive Dashboard shows all the important KPIs of your app. All data displayed is real-time
                data and are updated instantly a user makes a purchase in your app. </p>
            </div>
          </div>
        </div>


        <div className="pb-12"/>


        <section className="bg-white py-8" id="product">
          <div className="container max-w-6xl mx-auto m-8">
            <HeaderH2
              headerText="Total Active Subscribers"
              widthClass="md:w-4/6"
            />
            <div className="flex flex-wrap md:mt-20">
              <div className="w-full sm:w-1/2 p-12">
                <img alt="LinkFive Dashboard all active subscribers"
                     title="LinkFive Dashboard all active subscribers"
                     style={{maxHeight: "400px"}}
                     src={Dashboard1}/>
              </div>
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  When a customer buys a subscription, he is an active subscriber.
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  All active subscribers are displayed on the LinkFive Dashboard each month. See the evolution of all
                  subscribers over the last months. With LinkFive, it doesn't matter if your users come from iOS,
                  Android or the web.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="pb-12"/>


        <section className="bg-white py-8" id="product">
          <div className="container max-w-6xl mx-auto m-8">
            <HeaderH2
              headerText="Monthly Recurring Revenue (MRR)"
              widthClass="md:w-4/6"
            />
            <div className="flex flex-wrap md:mt-20">
              <div className="w-full sm:w-1/2 p-12">
                <img alt="LinkFive Dashboard MRR"
                     title="LinkFive Dashboard MRR"
                     style={{maxHeight: "400px"}}
                     src={Dashboard2}/>
              </div>
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Monthly Recurring Revenue (MRR) determines how much you earn per month.
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  If your client subscribes for one year, your monthly recurring revenue is 1/12 of the annual subscription for the coming year. MRR is one of the most important KPIs for companies selling subscriptions.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="pb-12"/>


        <section className="bg-white py-8" id="product">
          <div className="container max-w-6xl mx-auto m-8">
            <HeaderH2
              headerText="Monthly Tracked Revenue (MTR)"
              widthClass="md:w-4/6"
            />
            <div className="flex flex-wrap md:mt-20">
              <div className="w-full sm:w-1/2 p-12">
                <img alt="LinkFive Dashboard MTR"
                     title="LinkFive Dashboard MTR"
                     style={{maxHeight: "400px"}}
                     src={Dashboard3}/>
              </div>
              <div className="sm:w-1/2 p-6">
                <h3 className="mt-2 mb-8 text-4xl font-extrabold leading-10 tracking-tight text-gray-800">
                  Monthly Tracked Revenue (MTR) determines how much revenue you make in a single month.
                </h3>
                <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-800">
                  If your client subscribes for one year, your monthly tracked revenue would be the full amount of the one year subscription. The MTR is important for the finance department as it indicates how much cash flow is available in a single month.
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
